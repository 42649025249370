var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"table-name":"Order Items"},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var id = ref.item.id;
return [_c('router-link',{staticClass:"grey--text",attrs:{"to":{
          name: 'orderItem',
          params: { id: id },
          query: { suffix: _vm.suffix },
        },"target":"_blank"}},[_vm._v(" #"+_vm._s(id)+" ")])]}},{key:"orderId",fn:function(ref){
        var orderId = ref.item.orderId;
return [_c('router-link',{staticClass:"grey--text",attrs:{"to":{
          name: 'orderItems',
          query: {
            orderId: orderId,
            suffix: _vm.suffix,
          },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(orderId)+" ")])]}},{key:"orderItemId",fn:function(ref){
        var orderItemId = ref.item.orderItemId;
return [_c('router-link',{staticClass:"grey--text",attrs:{"to":{
          name: 'orderItems',
          query: {
            itemId: orderItemId,
            suffix: _vm.suffix,
          },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(orderItemId)+" ")])]}},{key:"productType",fn:function(ref){
        var productType = ref.item.productType;
return [_c('router-link',{staticClass:"grey--text",attrs:{"to":{
          name: 'profitProducts',
          query: {
            name: productType,
            suffix: _vm.suffix,
          },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(productType)+" ")])]}},{key:"rush",fn:function(ref){
        var _state = ref.item.rush;
return [_c('dsb-state-icon',{attrs:{"state":_state}})]}},{key:"truckDate",fn:function(ref){
        var _dateForFormatting = ref.item.truckDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }