var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"table-name":"Press Sheets"},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var id = ref.item.id;
return [_c('router-link',{staticClass:"grey--text",attrs:{"to":{
          name: 'pressSheets',
          query: {
            pressSheetId: id,
            suffix: _vm.suffix,
          },
        },"target":"_blank"}},[_vm._v(" #"+_vm._s(id)+" ")])]}},{key:"created",fn:function(ref){
        var _state = ref.item.created;
return [_c('dsb-state-icon',{attrs:{"state":_state}})]}},{key:"createDate",fn:function(ref){
        var _dateForFormatting = ref.item.createDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"distributed",fn:function(ref){
        var _state = ref.item.distributed;
return [_c('dsb-state-icon',{attrs:{"state":_state}})]}},{key:"distributeDate",fn:function(ref){
        var _dateForFormatting = ref.item.distributeDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"machine",fn:function(ref){
        var machine = ref.item.machine;
return [_c('router-link',{staticClass:"grey--text",attrs:{"to":{
          name: 'profitMachines',
          query: {
            name: machine,
            suffix: _vm.suffix,
          },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(machine)+" ")])]}},{key:"sourceUrl",fn:function(ref){
        var sourceUrl = ref.item.sourceUrl;
return [_c('a',{staticClass:"grey--text",attrs:{"href":sourceUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(sourceUrl)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }