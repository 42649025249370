<template>
  <v-layout>
    <vbt-table
      :headers="headers"
      :items="items"
      table-name="Press Sheets"
    >
      <template #id="{ item: { id } }">
        <router-link
          :to="{
            name: 'pressSheets',
            query: {
              pressSheetId: id,
              suffix,
            },
          }"
          class="grey--text"
          target="_blank"
        >
          #{{ id }}
        </router-link>
      </template>

      <template #created="{ item: { created: _state } }">
        <dsb-state-icon :state="_state" />
      </template>

      <template #createDate="{ item: { createDate: _dateForFormatting } }">
        {{ formatDisplayDate(_dateForFormatting) }}
      </template>

      <template #distributed="{ item: { distributed: _state } }">
        <dsb-state-icon :state="_state" />
      </template>

      <template #distributeDate="{ item: { distributeDate: _dateForFormatting } }">
        {{ formatDisplayDate(_dateForFormatting) }}
      </template>

      <template #machine="{ item: { machine } }">
        <router-link
          :to="{
            name: 'profitMachines',
            query: {
              name: machine,
              suffix,
            },
          }"
          class="grey--text"
          target="_blank"
        >
          {{ machine }}
        </router-link>
      </template>

      <template #sourceUrl="{ item: { sourceUrl } }">
        <a
          :href="sourceUrl"
          class="grey--text"
          target="_blank"
        >
          {{ sourceUrl }}
        </a>
      </template>
    </vbt-table>
  </v-layout>
</template>

<script>
import { formatDisplayDate } from '@helpers';

const headers = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Created', value: 'created' },
  { text: 'Create Date', value: 'createDate' },
  { text: 'Create Details', value: 'createDetails' },
  { text: 'Distributed', value: 'distributed' },
  { text: 'Distribute Date', value: 'distributeDate' },
  { text: 'Distribute Details', value: 'distributeDetails' },
  { text: 'Machine', value: 'machine' },
  { text: 'Source Url', value: 'sourceUrl' },
]);

export default {
  name: 'DsbRuleTestResultPressSheets',

  props: {
    suffix: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers,
    };
  },

  methods: {
    formatDisplayDate,
  },
};
</script>
