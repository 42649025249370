import { mapState, mapActions } from 'vuex';

import { useDataLoading, usePagination } from '@mixins/factories';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { namespace as RulesNamespace, ActionTypes as RulesActionTypes } from '../../store.types';

import { RulesApiService } from '../../_services/rules.api.service';

import DsbPressSheetsByPeriods from '../../_components/DsbPressSheetsByPeriods.vue';
import DsbRuleTestResultViewer from '../../_components/DsbRuleTestResultViewer';
import DsbRuleForm from '../../_components/DsbRuleForm';

const dataLoadingOptions = {
  getterName: 'getData',
  parameterize: true,
  searchable: false,
};
const paginationOptions = { contentName: 'ruleHistory' };

const historyHeaders = Object.freeze([
  { text: 'Processed Date', value: 'processed' },
  { text: 'Operator', value: 'operator' },
  { text: 'Details', value: 'details' },
  { text: '', value: 'actions', width: '50px' },
]);

const tabsConfig = Object.freeze([
  { text: 'Information', key: 'info' },
  { text: 'History', key: 'history' },
]);

export default {
  name: 'DsbRule',

  components: {
    DsbPressSheetsByPeriods,
    DsbRuleTestResultViewer,
    DsbRuleForm,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
  ],

  data() {
    return {
      tabsConfig,
      historyHeaders,

      statistics: [],
      acceptableItemsCount: 0,

      currentTab: 'info',

      ruleName: '',

      testResult: {},
      testResultDialog: false,

      ruleLoading: false,
      ruleHistoryLoading: false,
      ruleStatisticsLoading: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapState(RulesNamespace, ['rule', 'ruleHistory', 'ruleHistoryTotalCount']),

    tags() {
      return this.rule.tags || [];
    },

    isLoading() {
      return this.ruleLoading || this.ruleHistoryLoading;
    },

    ruleId() {
      return this.$route.params.id;
    },

    isActiveRule() {
      // @TODO remove string parser when backend create enums for rule states
      return (this.rule.status || []).some((v) => v.toLowerCase() === 'active');
    },
  },

  watch: {
    'rule.name': {
      handler(newValue) {
        this.ruleName = newValue || '';
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(RulesNamespace, {
      getData(dispatch, response) {
        this.wrapToLoadingFn({
          req: dispatch.bind({}, RulesActionTypes.GET_RULE, response),
          loadingFlagName: 'ruleLoading',
          onSuccess: () => this.$refs.ruleForm.resetForm(),
        });

        this.wrapToLoadingFn({
          req: dispatch.bind({}, RulesActionTypes.GET_RULE_HISTORY, response),
          loadingFlagName: 'ruleHistoryLoading',
        });

        this.getRuleStatistic();
      },
    }),

    getRuleStatistic() {
      this.wrapToLoadingFn({
        req: RulesApiService.getRuleStatistic.bind({}, {
          suffix: this.suffix,
          id: this.ruleId,
        }),
        loadingFlagName: 'ruleStatisticsLoading',

        onSuccess: ({ acceptableItemsCount, statistic }) => {
          this.acceptableItemsCount = acceptableItemsCount || 0;
          this.statistics = statistic || [];
        },
      });
    },

    updateRule(form) {
      this.$VBlackerTheme.alert.warning({
        text: 'Update rule?',
      }, () => this.wrapToLoadingFn({
        req: RulesApiService.updateRule.bind({}, {
          suffix: this.suffix,
          id: this.$route.params.id,
          data: form,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Rule successfully updated');
          this.$_fetchData();
        },
      }));
    },

    testRule() {
      this.$VBlackerTheme.alert.warning({
        label: 'Provider Order, Order Item ID',
        withValidation: false,
        text: 'Run test?',
        withInput: true,
      }, ({ value }) => this.wrapToLoadingFn({
        req: RulesApiService.testRule.bind({}, {
          testSearchTerm: value || null,
          suffix: this.suffix,
          id: this.ruleId,
        }),
        fullscreenLoadingSpinner: true,

        onSuccess: (data) => {
          this.openTestResultDialog(data);
        },
      }));
    },

    updateAndTestRule(form) {
      this.$VBlackerTheme.alert.warning({
        text: 'Update rule?',
      }, () => this.wrapToLoadingFn({
        req: RulesApiService.updateRule.bind({}, {
          suffix: this.suffix,
          id: this.ruleId,
          data: form,
        }),
        fullscreenLoadingSpinner: true,

        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Rule successfully updated');
          this.$_fetchData();

          this.testRule();
        },

        onReject: (e) => {
          this.$VBlackerTheme.notification.error(e.msg || `${e}`);
          this.$VBlackerTheme.closeLoadingSpinner();
        },
      }));
    },

    openTestResultDialog(result) {
      this.testResult = result;
      this.testResultDialog = true;
    },

    switchRuleState() {
      this.$VBlackerTheme.alert.warning({
        text: `${this.isActiveRule ? 'Disable' : 'Enable'} rule?`,
      }, () => this.wrapToLoadingFn({
        req: RulesApiService.switchRuleState.bind({}, {
          state: this.isActiveRule,
          suffix: this.suffix,
          id: this.ruleId,
        }),
        onSuccess: () => {
          this.$VBlackerTheme.notification.success(
            `Rule successfully ${this.isActiveRule ? 'disabled' : 'enabled'}`,
          );
          this.$_fetchData();
        },
      }));
    },

    resetRuleName() {
      this.ruleName = this.rule.name || '';
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
