<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <router-link
        :to="rulesRouterLink"
        target="_blank"
      >
        <strong v-on="on">
          Periods:
          <span
            v-for="(period, i) in periods"
            :key="i"
            :class="{
              'mr-1': i < periods.length - 1,
              'ml-1': i === 0,
            }"
          >
            {{ period.pressSheetCount }}
            <strong v-if="i < periods.length - 1">
              /
            </strong>
          </span>
        </strong>
      </router-link>
    </template>

    Press Sheets by periods
    ({{ periods.reduce((acc, { period }) => acc ? `${acc} / ${period}` : period, '') }})
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex';

import { AppLocationsNamespace } from '@store/types';

export default {
  name: 'DsbPressSheetsByPeriods',

  props: {
    ruleId: {
      type: [String, Number],
      required: true,
    },
    periods: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    rulesRouterLink() {
      return {
        name: 'pressSheets',
        query: {
          suffix: this.suffix,
          ruleId: this.ruleId,
          pageNum: '1',
        },
      };
    },
  },
};
</script>
