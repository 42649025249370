<template>
  <vbt-dialog
    v-model="dialog"
    fullscreen-header-class-name="result-header"
    title="Test result"
    hide-submit-btn
    hide-footer
    fullscreen
  >
    <v-layout
      style="font-size: 17px;"
      class="warning--text"
    >
      <strong class="mr-1">
        Error:
      </strong>
      {{ result.error || '-' }}
    </v-layout>

    <v-layout
      class="mt-2 mb-5"
      align-center
    >
      <v-divider class="info" />
      <strong class="mx-3 info--text">
        {{ baskets.length ? 'Baskets' : 'No baskets' }}
      </strong>
      <v-divider class="info" />
    </v-layout>

    <template v-if="baskets.length">
      <v-layout
        v-for="(basket, i) in baskets"
        :key="`basket-${i}`"
        column
      >
        <v-layout>
          <v-flex sm3>
            <v-layout column>
              <strong>
                Basket #{{ basket.id }}
              </strong>

              <strong>
                Created at {{ formatDisplayDate(basket.created) }}
              </strong>
            </v-layout>
          </v-flex>

          <v-divider
            class="mx-2"
            vertical
          />

          <v-flex sm3>
            <v-layout column>
              <router-link
                :to="{
                name: 'ruleEdit',
                params: { id: basket.filter.id },
                query: { suffix },
              }"
                class="grey--text"
                target="_blank"
              >
                <strong>
                  Rule #{{ basket.filter.id }}
                </strong>
              </router-link>

              <router-link
                :to="{
                  name: 'ruleSlice',
                  params: { id: basket.filter.sliceId },
                  query: { suffix },
                }"
                class="grey--text"
                target="_blank"
              >
                <strong>
                  Slice #{{ basket.filter.sliceId }}
                </strong>
              </router-link>
            </v-layout>
          </v-flex>

          <v-divider
            class="mx-2"
            vertical
          />

          <v-flex sm3>
            <v-layout column>
              <v-layout>
                <strong class="result-label">
                  Product Type:
                </strong>
                <router-link
                  :to="{
                    name: 'profitProducts',
                    query: {
                      name: basket.filter.productType,
                      suffix,
                    },
                  }"
                  class="grey--text"
                  target="_blank"
                >
                  {{ basket.filter.productType }}
                </router-link>
              </v-layout>

              <v-layout>
                <strong class="result-label">
                  Substrate:
                </strong>
                <router-link
                  :to="{
                    name: 'profitSubstrates',
                    query: {
                      name: basket.filter.substrate,
                      suffix,
                    },
                  }"
                  class="grey--text"
                  target="_blank"
                >
                  {{ basket.filter.substrate }}
                </router-link>
              </v-layout>
            </v-layout>
          </v-flex>

          <v-divider
            class="mx-2"
            vertical
          />

          <v-flex sm3>
            <v-layout column>
              <v-layout>
                <strong class="result-label">
                  Min Quantity:
                </strong>
                {{ basket.filter.minQuantity }}
              </v-layout>

              <v-layout>
                <strong class="result-label">
                  Max Quantity:
                </strong>
                {{ basket.filter.maxQuantity }}
              </v-layout>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-divider class="mt-4" />

        <press-sheets
          :items="basket.pressSheets || []"
          :suffix="suffix"
        />

        <order-items
          :items="basket.orderItems || []"
          :suffix="suffix"
        />

        <v-layout
          class="my-9"
          align-center
        >
          <v-divider class="info" />
          <strong class="mx-3 font-italic info--text">
            End of basket
          </strong>
          <v-divider class="info" />
        </v-layout>
      </v-layout>
    </template>
  </vbt-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { useDialog } from '@mixins/factories';

import { formatDisplayDate } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import PressSheets from './_pressSheets.vue';
import OrderItems from './_orderItems.vue';

export default {
  name: 'DsbRuleTestResultViewer',

  props: {
    result: {
      type: Object,
      required: true,
    },
  },

  components: {
    PressSheets,
    OrderItems,
  },

  mixins: [
    useDialog(),
  ],

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    baskets() {
      return this.result.baskets || [];
    },
  },

  methods: {
    formatDisplayDate,
  },
};
</script>

<style lang="css" scoped>
.result-label {
  margin-right: 5px;
  width: 95px;
}
</style>

<style lang="css">
.result-header {
  max-height: 64px;
  margin-bottom: 20px;
}
</style>
