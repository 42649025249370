var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{attrs:{"to":_vm.rulesRouterLink,"target":"_blank"}},[_c('strong',_vm._g({},on),[_vm._v(" Periods: "),_vm._l((_vm.periods),function(period,i){return _c('span',{key:i,class:{
            'mr-1': i < _vm.periods.length - 1,
            'ml-1': i === 0,
          }},[_vm._v(" "+_vm._s(period.pressSheetCount)+" "),(i < _vm.periods.length - 1)?_c('strong',[_vm._v(" / ")]):_vm._e()])})],2)])]}}])},[_vm._v(" Press Sheets by periods ("+_vm._s(_vm.periods.reduce(function (acc, ref) {
                    var period = ref.period;

                    return acc ? (acc + " / " + period) : period;
}, ''))+") ")])}
var staticRenderFns = []

export { render, staticRenderFns }