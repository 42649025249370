var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('vbt-content-box',{attrs:{"loading":_vm.isLoading,"title":"Tags"}},[_c('v-flex',{staticClass:"mt-n3",attrs:{"sm12":""}},_vm._l((_vm.tags),function(tag){return _c('router-link',{key:tag,attrs:{"to":{
          name: 'rules',
          query: {
            suffix: _vm.suffix,
            pageNum: '1',
            status: 'Active',
            tags: [tag]
          },
        },"target":"_blank"}},[_c('v-chip',{staticClass:"mx-1 mt-3",staticStyle:{"cursor":"pointer"},attrs:{"color":"success"}},[_vm._v(" #"+_vm._s(tag)+" ")])],1)}),1)],1),_c('vbt-tabs',{attrs:{"tabs":_vm.tabsConfig,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"right-bar",fn:function(){return [_c('v-layout',{staticClass:"pr-3",attrs:{"align-center":""}},[_c('v-spacer'),(_vm.currentTab === 'info')?_c('v-layout',[_c('strong',{staticClass:"mr-2"},[_vm._v(" Rule Name: ")]),_c('v-text-field',{staticClass:"vbt-hide-form-control-details",attrs:{"height":"20px","dense":""},model:{value:(_vm.ruleName),callback:function ($$v) {_vm.ruleName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"ruleName"}})],1):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('strong',_vm._g({staticClass:"mr-3"},on),[_vm._v(" Items: "+_vm._s(_vm.acceptableItemsCount)+"; ")])]}}])},[_vm._v(" Acceptable Items ")]),_c('dsb-press-sheets-by-periods',{attrs:{"periods":_vm.statistics,"rule-id":_vm.ruleId}}),_c('v-btn',{staticClass:"mr-1",attrs:{"loading":_vm.ruleStatisticsLoading,"icon":"","small":"","color":"info"},on:{"click":_vm.getRuleStatistic}},[_c('v-icon',[_vm._v(" refresh ")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"color":(_vm.isActiveRule || _vm.isLoading) ? 'success' : 'error',"loading":_vm.isLoading,"x-small":""},on:{"click":_vm.switchRuleState}},[_vm._v(" "+_vm._s(_vm.isActiveRule ? 'Disable' : 'Enable')+" rule ")])],1)]},proxy:true}]),model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-layout',{attrs:{"slot":"info"},slot:"info"},[_c('dsb-rule-form',{ref:"ruleForm",attrs:{"is-active-rule":_vm.isActiveRule,"rule-name":_vm.ruleName,"rule":_vm.rule},on:{"test":_vm.testRule,"submit":_vm.updateRule,"reset:form":_vm.resetRuleName,"submit:and:test":_vm.updateAndTestRule}}),_c('dsb-rule-test-result-viewer',{attrs:{"result":_vm.testResult},model:{value:(_vm.testResultDialog),callback:function ($$v) {_vm.testResultDialog=$$v},expression:"testResultDialog"}})],1),_c('v-layout',{attrs:{"slot":"history","column":""},slot:"history"},[_c('vbt-table',{attrs:{"headers":_vm.historyHeaders,"items":_vm.ruleHistory},scopedSlots:_vm._u([{key:"processed",fn:function(ref){
        var _dateForFormatting = ref.item.processed;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_dateForFormatting))+" ")]}},{key:"actions",fn:function(ref){
        var item = ref.item;
return [(item.sliceId)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"to":{
                  name: 'ruleHistoryComparer',
                  query: {
                    suffix: _vm.suffix,
                  },
                  params: {
                    id: _vm.ruleId,
                    historyId: item.id,
                  },
                },"target":"_blank","color":"info","small":"","icon":""}},on),[_c('v-icon',[_vm._v(" insert_drive_file ")])],1)]}}],null,true)},[_vm._v(" Show rule compare ")]):_vm._e()]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount,"total-count":_vm.ruleHistoryTotalCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }