<template>
  <v-layout>
    <vbt-table
      :headers="headers"
      :items="items"
      table-name="Order Items"
    >
      <template #id="{ item: { id } }">
        <router-link
          :to="{
            name: 'orderItem',
            params: { id },
            query: { suffix },
          }"
          class="grey--text"
          target="_blank"
        >
          #{{ id }}
        </router-link>
      </template>

      <template #orderId="{ item: { orderId } }">
        <router-link
          :to="{
            name: 'orderItems',
            query: {
              orderId,
              suffix,
            },
          }"
          class="grey--text"
          target="_blank"
        >
          {{ orderId }}
        </router-link>
      </template>

      <template #orderItemId="{ item: { orderItemId } }">
        <router-link
          :to="{
            name: 'orderItems',
            query: {
              itemId: orderItemId,
              suffix,
            },
          }"
          class="grey--text"
          target="_blank"
        >
          {{ orderItemId }}
        </router-link>
      </template>

      <template #productType="{ item: { productType } }">
        <router-link
          :to="{
            name: 'profitProducts',
            query: {
              name: productType,
              suffix,
            },
          }"
          class="grey--text"
          target="_blank"
        >
          {{ productType }}
        </router-link>
      </template>

      <template #rush="{ item: { rush: _state } }">
        <dsb-state-icon :state="_state" />
      </template>

      <template #truckDate="{ item: { truckDate: _dateForFormatting } }">
        {{ formatDisplayDate(_dateForFormatting) }}
      </template>
    </vbt-table>
  </v-layout>
</template>

<script>
import { formatDisplayDate } from '@helpers';

const headers = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Order ID', value: 'orderId' },
  { text: 'Order Item ID', value: 'orderItemId' },
  { text: 'ProductType', value: 'productType' },
  { text: 'Rush', value: 'rush' },
  { text: 'Truck Date', value: 'truckDate' },
]);

export default {
  name: 'DsbRuleTestResultOrderItems',

  props: {
    suffix: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers,
    };
  },

  methods: {
    formatDisplayDate,
  },
};
</script>
