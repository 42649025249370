var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-dialog',{attrs:{"fullscreen-header-class-name":"result-header","title":"Test result","hide-submit-btn":"","hide-footer":"","fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-layout',{staticClass:"warning--text",staticStyle:{"font-size":"17px"}},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Error: ")]),_vm._v(" "+_vm._s(_vm.result.error || '-')+" ")]),_c('v-layout',{staticClass:"mt-2 mb-5",attrs:{"align-center":""}},[_c('v-divider',{staticClass:"info"}),_c('strong',{staticClass:"mx-3 info--text"},[_vm._v(" "+_vm._s(_vm.baskets.length ? 'Baskets' : 'No baskets')+" ")]),_c('v-divider',{staticClass:"info"})],1),(_vm.baskets.length)?_vm._l((_vm.baskets),function(basket,i){return _c('v-layout',{key:("basket-" + i),attrs:{"column":""}},[_c('v-layout',[_c('v-flex',{attrs:{"sm3":""}},[_c('v-layout',{attrs:{"column":""}},[_c('strong',[_vm._v(" Basket #"+_vm._s(basket.id)+" ")]),_c('strong',[_vm._v(" Created at "+_vm._s(_vm.formatDisplayDate(basket.created))+" ")])])],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-flex',{attrs:{"sm3":""}},[_c('v-layout',{attrs:{"column":""}},[_c('router-link',{staticClass:"grey--text",attrs:{"to":{
              name: 'ruleEdit',
              params: { id: basket.filter.id },
              query: { suffix: _vm.suffix },
            },"target":"_blank"}},[_c('strong',[_vm._v(" Rule #"+_vm._s(basket.filter.id)+" ")])]),_c('router-link',{staticClass:"grey--text",attrs:{"to":{
                name: 'ruleSlice',
                params: { id: basket.filter.sliceId },
                query: { suffix: _vm.suffix },
              },"target":"_blank"}},[_c('strong',[_vm._v(" Slice #"+_vm._s(basket.filter.sliceId)+" ")])])],1)],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-flex',{attrs:{"sm3":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',[_c('strong',{staticClass:"result-label"},[_vm._v(" Product Type: ")]),_c('router-link',{staticClass:"grey--text",attrs:{"to":{
                  name: 'profitProducts',
                  query: {
                    name: basket.filter.productType,
                    suffix: _vm.suffix,
                  },
                },"target":"_blank"}},[_vm._v(" "+_vm._s(basket.filter.productType)+" ")])],1),_c('v-layout',[_c('strong',{staticClass:"result-label"},[_vm._v(" Substrate: ")]),_c('router-link',{staticClass:"grey--text",attrs:{"to":{
                  name: 'profitSubstrates',
                  query: {
                    name: basket.filter.substrate,
                    suffix: _vm.suffix,
                  },
                },"target":"_blank"}},[_vm._v(" "+_vm._s(basket.filter.substrate)+" ")])],1)],1)],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-flex',{attrs:{"sm3":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',[_c('strong',{staticClass:"result-label"},[_vm._v(" Min Quantity: ")]),_vm._v(" "+_vm._s(basket.filter.minQuantity)+" ")]),_c('v-layout',[_c('strong',{staticClass:"result-label"},[_vm._v(" Max Quantity: ")]),_vm._v(" "+_vm._s(basket.filter.maxQuantity)+" ")])],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('press-sheets',{attrs:{"items":basket.pressSheets || [],"suffix":_vm.suffix}}),_c('order-items',{attrs:{"items":basket.orderItems || [],"suffix":_vm.suffix}}),_c('v-layout',{staticClass:"my-9",attrs:{"align-center":""}},[_c('v-divider',{staticClass:"info"}),_c('strong',{staticClass:"mx-3 font-italic info--text"},[_vm._v(" End of basket ")]),_c('v-divider',{staticClass:"info"})],1)],1)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }